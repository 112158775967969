import hardtack from 'hardtack'

import Units from 'utils/units'
import polyfill from 'utils/switch-units/polyfill'
import {
  saveValue,
  getFullName,
  useSavedValue,
  updateTextContent,
  cUpdateTextContent,
  replaceValuesInPhrase,
  convertKphToMph,
  convertKphToKnot,
  convertCmToInches,
  convertToFahrenheit,
  convertMetersToFeet,
  convertKilometersToMiles,
  convertMillimetersToInches
} from 'utils/switch-units/helpers'
import {
  convertWindImageUrlToImperial,
  convertSwellImageUrlToImperial
} from 'utils/switch-units/convert-value-images'

const eventName = 'switchUnits'

polyfill()

const currentUnits = {
  temperature: 'Metric',
  length: 'Metric',
  speed: 'Metric'
}

const unitsConversion = [
  {
    type: 'temperature',
    selector: 'span.temp',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(convertToFahrenheit(value, { round: true }), node)
    }
  },
  {
    type: 'temperature',
    selector: 'span.tempu',
    conversion: {
      Imperial: cUpdateTextContent('F')
    }
  },
  {
    type: 'height',
    selector: 'span.height',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(convertMetersToFeet(value, { round: true }), node)
    }
  },
  {
    type: 'height',
    selector: 'span.heightfl',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(convertMetersToFeet(value, { roundBy: 100 }), node)
    }
  },
  {
    type: 'height',
    selector: 'text.swell-icon__val',
    conversion: {
      Imperial: (node, value) => {
        /* eslint-disable no-param-reassign */
        value = convertMetersToFeet(value)

        if (value >= 10) {
          value = Math.round(value)
        } else {
          value = (Math.round((value / 5) * 10) * 5) / 10
        }
        updateTextContent(value, node)
        /* eslint-enable no-param-reassign */
      }
    }
  },
  {
    type: 'height',
    selector: 'span.heightu',
    conversion: {
      Imperial: cUpdateTextContent('ft')
    }
  },
  {
    type: 'distance',
    selector: 'span.dist',
    conversion: {
      Imperial: (node, value) =>
        updateTextContent(
          convertKilometersToMiles(value, {
            roundBy: 0.1,
            toFixed: 1
          }),
          node
        )
    }
  },
  {
    type: 'distance',
    selector: 'span.distu',
    conversion: {
      Imperial: cUpdateTextContent('miles')
    }
  },
  {
    type: 'snow',
    selector: 'span.snow',
    conversion: {
      Imperial: (node, value) => updateTextContent(convertCmToInches(value, { precision: 1 }), node)
    }
  },
  {
    type: 'snow',
    selector: 'span.snowht',
    conversion: {
      Imperial: (node, value) => updateTextContent(convertCmToInches(value, { round: true }), node)
    }
  },
  {
    type: 'snow',
    selector: 'span.snowu',
    conversion: {
      Imperial: cUpdateTextContent('in')
    }
  },
  {
    type: 'wind',
    selector: 'span.wind,.wind-icon-val',
    conversion: {
      Imperial: node =>
        updateTextContent(convertKphToMph(node.dataset.initial, { roundBy: 5 }), node),
      Knot: node => updateTextContent(convertKphToKnot(node.dataset.initial, { roundBy: 5 }), node)
    }
  },
  {
    type: 'wind',
    selector: 'span.wind-precise',
    conversion: {
      Imperial: node =>
        updateTextContent(convertKphToMph(node.dataset.initial, { roundBy: 1 }), node),
      Knot: node => updateTextContent(convertKphToKnot(node.dataset.initial, { roundBy: 1 }), node)
    }
  },
  {
    type: 'wind',
    selector: 'span.windu',
    conversion: {
      Imperial: cUpdateTextContent('mph'),
      Knot: cUpdateTextContent('knots')
    }
  },

  {
    type: 'rain',
    selector: 'span.rain',
    conversion: {
      Imperial: (node, value) => {
        let convertedValue = convertMillimetersToInches(value, {
          toFixed: 2
        })
        if (convertedValue >= 0.1) {
          convertedValue = Number(convertedValue).toFixed(1)
        }
        updateTextContent(convertedValue, node)
      }
    }
  },
  {
    type: 'rain',
    selector: 'span.rainu',
    conversion: {
      Imperial: cUpdateTextContent('in')
    }
  }
]

function convertNodes(cUnits, nUnits, { container, els, type }) {
  const selectors = els.map(el => el.selector)
  const nodes = container.querySelectorAll(selectors.join(', '))

  /* eslint-disable no-plusplus, no-continue */
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const value = node.textContent
    if (value === '' || value === '-' || value === '—') continue

    saveValue(cUnits, value, node)
    if (useSavedValue(nUnits, node)) continue

    for (let j = 0; j < els.length; j++) {
      const el = els[j]

      if (node.matches(el.selector)) {
        const conversion = el.conversion[nUnits]
        if (conversion) conversion(node, value, type)
        break
      }
    }
  }
}

const phraseConversion = {
  temperature(node, value, units) {
    if (units === 'Imperial')
      /* C -> F */
      return replaceValuesInPhrase(node, value, {
        pattern: /([-\d.]+)(°|&deg;)C/g,
        convert(num) {
          return convertToFahrenheit(num, { round: true })
        },
        units: '°F'
      })

    return value
  },
  length(node, value, units) {
    /* eslint-disable no-param-reassign */
    if (units === 'Imperial') {
      /* mm -> in */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)mm/g,
        convert(num) {
          return convertMillimetersToInches(num, { precision: 1 })
        },
        units: 'in'
      })

      /* km -> mi */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+) km/g,
        convert(num) {
          return convertKilometersToMiles(num, { round: true })
        },
        units: ' miles'
      })

      /* m -> ft */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)( ?)m/g,
        convert(num) {
          return convertMetersToFeet(num, { round: true })
        },
        units: ' ft'
      })

      /* cm -> in */
      value = replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)cm/g,
        convert(num) {
          return convertCmToInches(num, { precision: 1 })
        },
        units: ' in'
      })
    }
    /* eslint-enable no-param-reassign */

    return value
  },
  speed(node, value, units) {
    if (units === 'Imperial')
      /* km/h -> mph */
      return replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)( ?)km\/h/g,
        convert(num) {
          return convertKphToMph(num, { round: true })
        },
        units: 'mph'
      })

    if (units === 'Knot')
      /* km/h -> knots */
      return replaceValuesInPhrase(node, value, {
        pattern: /([\d.]+)( ?)km\/h/g,
        convert(num) {
          return convertKphToKnot(num, { round: true })
        },
        units: 'knots'
      })

    return value
  }
}

function convertPhrases(cUnits, nUnits, { container }) {
  const nodes = container.querySelectorAll('span.phrase')

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const { textContent } = node
    if (textContent === '' || textContent === '-') continue

    saveValue(getFullName(cUnits), textContent, node)
    if (useSavedValue(getFullName(nUnits), node)) continue

    let value = node.dataset.initial
    /* eslint-disable-next-line no-restricted-syntax */
    for (const type in cUnits)
      if (Object.hasOwnProperty.call(nUnits, type))
        value = phraseConversion[type](node, value, nUnits[type])
      else value = phraseConversion[type](node, value, cUnits[type])
  }
  /* eslint-enable no-plusplus, no-continue */
}

function convertImagesOld(className, convert, cUnits, nUnits) {
  const imgs = document.getElementsByClassName(className)
  Array.prototype.forEach.call(imgs, img => {
    saveValue(cUnits, img.src, img)
    if (useSavedValue(nUnits, img, 'src')) return

    const newSrc = convert(img.src)
    if (newSrc === null) return
    img.src = newSrc /* eslint-disable-line no-param-reassign */
  })
}

const CLASS_NAME_INACTIVE = 'inactive_units'

function updateLinks(newUnits) {
  const unitsLink = document.querySelectorAll('.units_link')
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < unitsLink.length; i++) {
    const link = unitsLink[i]

    if (newUnits === 'Metric') {
      if (link.classList.contains('metric')) link.classList.add(CLASS_NAME_INACTIVE)
      if (link.classList.contains('imperial')) link.classList.remove(CLASS_NAME_INACTIVE)
    }

    if (newUnits === 'Imperial') {
      if (link.classList.contains('metric')) link.classList.remove(CLASS_NAME_INACTIVE)
      if (link.classList.contains('imperial')) link.classList.add(CLASS_NAME_INACTIVE)
    }
  }

  const metric = document.getElementById('metricradio')
  const imperial = document.getElementById('imperialradio')
  if (metric && newUnits === 'Metric') metric.checked = true
  if (imperial && newUnits === 'Imperial') imperial.checked = true
}

const compatMap = {
  Knot: 'knt',
  Metric: 'met',
  Imperial: 'imp',
  temperature: 'temp',
  length: 'len',
  speed: 'spd'
}

function reverseObj(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    acc[obj[key]] = key
    return acc
  }, {})
}

function setCookie(units) {
  const fcUnits = Object.keys(units)
    .map(unitType => `${compatMap[unitType]}.${compatMap[units[unitType]]}`)
    .join('_')

  hardtack.set('_fcunits', fcUnits, {
    path: '/',
    secure: true,
    samesite: 'lax',
    maxAge: 60 * 60 * 24 * 365
  })
}

const typesMap = {
  speed: ['wind'],
  temperature: ['temperature'],
  length: ['height', 'distance', 'snow', 'rain']
}

function convertElementUnits(elementUnits, newUnits, container) {
  if (elementUnits.temperature !== newUnits.temperature)
    convertNodes(elementUnits.temperature, newUnits.temperature, {
      container,
      els: unitsConversion.filter(el => typesMap.temperature.some(type => type === el.type)),
      type: 'temperature'
    })

  if (elementUnits.length !== newUnits.length) {
    convertNodes(elementUnits.length, newUnits.length, {
      container,
      els: unitsConversion.filter(el => typesMap.length.some(type => type === el.type))
    })

    /* legacy */
    convertImagesOld(
      'swellimg',
      convertSwellImageUrlToImperial,
      elementUnits.length,
      newUnits.length
    )
  }

  if (elementUnits.speed !== newUnits.speed) {
    convertNodes(elementUnits.speed, newUnits.speed, {
      container,
      els: unitsConversion.filter(el => typesMap.speed.some(type => type === el.type))
    })

    /* legacy */
    if (elementUnits.speed !== 'Knot' && newUnits.speed !== 'Knot')
      convertImagesOld('windimg', convertWindImageUrlToImperial, elementUnits.speed, newUnits.speed)
  }

  convertPhrases(elementUnits, newUnits, { container })

  return true
}

function convertUnits(newUnits, { container, writeCookie = true }) {
  if (currentUnits.temperature !== newUnits.temperature)
    convertNodes(currentUnits.temperature, newUnits.temperature, {
      container,
      els: unitsConversion.filter(el => typesMap.temperature.some(type => type === el.type)),
      type: 'temperature'
    })

  if (currentUnits.length !== newUnits.length) {
    convertNodes(currentUnits.length, newUnits.length, {
      container,
      els: unitsConversion.filter(el => typesMap.length.some(type => type === el.type))
    })

    /* legacy */
    convertImagesOld(
      'swellimg',
      convertSwellImageUrlToImperial,
      currentUnits.length,
      newUnits.length
    )
  }

  if (currentUnits.speed !== newUnits.speed) {
    convertNodes(currentUnits.speed, newUnits.speed, {
      container,
      els: unitsConversion.filter(el => typesMap.speed.some(type => type === el.type))
    })

    /* legacy */
    if (currentUnits.speed !== 'Knot' && newUnits.speed !== 'Knot')
      convertImagesOld('windimg', convertWindImageUrlToImperial, currentUnits.speed, newUnits.speed)
  }

  convertPhrases(currentUnits, newUnits, { container })

  currentUnits.temperature = newUnits.temperature
  currentUnits.length = newUnits.length
  currentUnits.speed = newUnits.speed

  document.dispatchEvent(
    new CustomEvent(eventName, {
      detail: {
        currentUnits
      }
    })
  )

  if (writeCookie) setCookie(currentUnits)

  return true
}

function switchUnits(newUnits) {
  /* eslint-disable-next-line no-param-reassign */
  if (newUnits === 'US') newUnits = 'Imperial'

  Units.change({
    temperature: newUnits,
    length: newUnits,
    speed: newUnits
  })
}

function initUnits(preferUnits) {
  const reversedCompatMap = reverseObj(compatMap)
  const fcunits = (hardtack.get('_fcunits') || '').split('_').reduce((acc, pair) => {
    const [unitType, value] = pair.split('.')
    if (unitType && value) acc[reversedCompatMap[unitType]] = reversedCompatMap[value]
    return acc
  }, {})

  const newUnits = {}
  Object.keys(currentUnits).forEach(type => {
    newUnits[type] = fcunits[type] || preferUnits
  })

  Units.change(newUnits)
  convertUnits(newUnits, { container: document.body, writeCookie: false })

  Units.onChange(function () {
    if (window.FCMAPS) {
      FCMAPS.switchUnits(Units.current.length === 'Metric' ? 'metric' : 'imperial')
    }

    convertUnits(Units.current, { container: document.body })
    updateLinks(newUnits)

    if (
      'ReactNativeWebView' in window &&
      'postMessage' in window.ReactNativeWebView &&
      typeof window.ReactNativeWebView.postMessage === 'function'
    ) {
      const event = {
        type: eventName,
        message: {
          speed: Units.current.speed,
          length: Units.current.length,
          temperature: Units.current.temperature
        }
      }

      window.ReactNativeWebView.postMessage(JSON.stringify(event))
    }
  })
}

window.initUnits = initUnits
window.switchUnits = switchUnits
window.currentUnits = currentUnits
window.convertElementUnits = convertElementUnits
