// eslint-disable-next-line import/no-unresolved
import { convertKphToMph, convertMetersToFeet } from 'utils/switch-units/helpers'

export function convertWindImageUrlToImperial(url) {
  const pattern = /(.*windicons\/wind[A-Z-]+)(\d+)(imperial|metric)\.gif/
  const result = pattern.exec(url)
  if (result === null) return null

  const converted = convertKphToMph(Number(result[2]), { roundBy: 5 })
  return `${result[1] + converted}imperial.gif`
}

export function convertSwellImageUrlToImperial(url) {
  const pattern = /(.*swellicons\/swell\.[A-Z-]+)\.(\d+)\.(imperial|metric)\.([\d-]+)\.gif/
  const result = pattern.exec(url)
  if (result === null) return null

  const converted = convertMetersToFeet(Number(result[2]), { roundBy: 5 })
  return `${result[1]}.${converted}.imperial.${result[4]}.gif`
}
