export function isNumber(value) {
  return !Number.isNaN(Number(value))
}

export function camelCase(values) {
  return values.map(value => value[0].toUpperCase() + value.slice(1)).join('')
}

export function updateTextContent(textContent, node) {
  /* eslint-disable-next-line no-param-reassign, no-return-assign */
  return (node.textContent = textContent)
}

export function cUpdateTextContent(textContent) {
  return function sideEffect(node) {
    return updateTextContent(textContent, node)
  }
}

export function saveValue(units, value, node) {
  /* eslint-disable no-param-reassign */
  if (!Object.hasOwnProperty.call(node.dataset, 'initial')) node.dataset.initial = value

  const key = `backup${units}`
  if (!Object.hasOwnProperty.call(node.dataset, key)) node.dataset[key] = value
  /* eslint-enable no-param-reassign */
}

export function useSavedValue(newUnits, node, use = 'textContent') {
  const key = `backup${newUnits}`

  if (Object.hasOwnProperty.call(node.dataset, key)) {
    if (use === 'textContent') updateTextContent(node.dataset[key], node)
    else node[use] = node.dataset[key] /* eslint-disable-line no-param-reassign */
    return true
  }

  return false
}

export function getFullName(units) {
  const values = []

  /* eslint-disable-next-line no-restricted-syntax */
  for (const type in units)
    if (Object.hasOwnProperty.call(units, type)) values.push(type, units[type])

  return camelCase(values)
}

export function replaceValuesInPhrase(node, text, settings) {
  // eslint-disable-next-line no-shadow
  const { units, convert, pattern } = settings

  let result
  /* eslint-disable-next-line no-cond-assign */
  while ((result = pattern.exec(text))) {
    if (isNumber(result[1]))
      /* eslint-disable-next-line no-param-reassign */
      text = text.replace(result[0], convert(result[1]) + units)
  }

  return updateTextContent(text, node)
}

function round(value) {
  return Math.round(value)
}

function precisionRound(value, decimal) {
  return Number(`${round(`${value}e${decimal}`)}e-${decimal}`)
}

export function roundBy(value, by) {
  return round(value / by) * by
}

function convert(value, settings) {
  /* eslint-disable no-param-reassign */ if (settings) {
    if (isNumber(settings.roundBy)) value = roundBy(value, settings.roundBy)
    if (isNumber(settings.precision)) value = precisionRound(value, settings.precision)
    if (isNumber(settings.toFixed)) value = value.toFixed(settings.toFixed)
    if (settings.round) value = round(value)
  }
  return value /* eslint-enable no-param-reassign */
}

export function KmToMiles(value) {
  return value * 0.6214
}

export function KmToKnot(value) {
  return value * 0.53996
}

export function convertToFahrenheit(value, settings) {
  return convert(value * 1.8 + 32, settings)
}

export function convertMetersToFeet(value, settings) {
  return convert(value * 3.281, settings)
}

export function convertKilometersToMiles(value, settings) {
  return convert(KmToMiles(value), settings)
}

export function convertCmToInches(value, settings) {
  return convert(value * 0.3937, settings)
}

export function convertKphToMph(value, settings) {
  return convert(KmToMiles(value), settings)
}

export function convertKphToKnot(value, settings) {
  return convert(KmToKnot(value), settings)
}

export function convertMillimetersToInches(value, settings) {
  return convert(value * 0.03937, settings)
}

export function convertHectarsToAcres(value) {
  return Math.round(value * 2.471052)
}

export function convertAcresToHectars(value) {
  return Math.round(value * 0.404686)
}
